<template>
  <div class="cod-risk-order__pop">
    <!-- 本单确认弹框 -->
    <SLazyMount>
      <s-dialog
        v-model:visible="drawerShow"
        :append-to-body="true"
        :close-on-click-modal="false"
        :show-close="true"
        @close-from-icon="closeCodRiskOrderPop"
      >
        <template #title>
          {{ language.SHEIN_KEY_PWA_25636 }}
        </template>
        <div
          class="cod-risk-order__text"
          v-html="codRiskOrderText"
        >
        </div>
        <template #footer>
          <s-button-group :width="'100%'">
            <s-button-group-item
              :type="['primary', 'H72PX']"
              @click="needOrCancelTheOrderEvt(1)"
            >
              {{
                language.SHEIN_KEY_PWA_25637
              }}
            </s-button-group-item>
            <s-button-group-item
              :type="['H72PX']"
              @click="isShowSecondconfirmation = true"
            >
              {{ language.SHEIN_KEY_PWA_25638 }}
            </s-button-group-item>
          </s-button-group>
        </template>
      </s-dialog>
    </SLazyMount>

    <!-- 本单二次确认弹框 -->
    <SLazyMount>
      <s-dialog
        v-model:visible="isShowSecondconfirmation"
        :append-to-body="true"
        :close-on-click-modal="false"
        :show-close="true"
        @close-from-icon="closeSecondconfirmation"
      >
        <div
          class="cod-risk-order__text"
        >
          {{
            language.SHEIN_KEY_PWA_33335
          }}
        </div>
        <template #footer>
          <s-button-group :width="'100%'">
            <s-button-group-item
              :type="['primary', 'H72PX']"
              @click="needOrCancelTheOrderEvt(2)"
            >
              {{
                language.SHEIN_KEY_PWA_25676
              }}
            </s-button-group-item>
            <s-button-group-item
              :type="['H72PX']"
              @click="closeSecondconfirmation"
            >
              {{ language.SHEIN_KEY_PWA_25642 }}
            </s-button-group-item>
          </s-button-group>
        </template>
      </s-dialog>
    </SLazyMount>

    <SLazyMount>
      <s-drawer
        v-model:visible="showPreCodeRiskOrder"
        :show-close="true"
        max-size="80%"
        :append-to-body="true"
        :close-on-click-modal="false"
        class="pre-cod-risk__pop"
        @close-from-icon="closePreCodRiskOrderPop"
      >
        <template #title>
          {{ needOrCancelType == 1 ? language.SHEIN_KEY_PWA_25639 : language.SHEIN_KEY_PWA_25640 }}
        </template>
        <div class="pre-cod-risk__warp">
          <div
            v-if="showPopover"
            class="drawer-popover"
          >
            <div class="drawer-popover-ctn">
              <i
                class="suiiconfont sui_icon_close_12px_2"
                @click.stop="showPopover = false"
              ></i>
              {{ language.SHEIN_KEY_PWA_25699 }}
            </div>
          </div>

          <!-- 本单商品信息展示区域 -->
          <div class="cur-order__warp">
            <div class="cur-order-title">
              <span class="order-info">{{ language.SHEIN_KEY_PWA_16822 }}: {{ curOrder.billno }}</span>
              <s-label
                v-if="needOrCancelType == 1"
                type="neutral"
              >
                {{ language.SHEIN_KEY_PWA_25697 }}
              </s-label>
              <s-label v-else>
                {{ language.SHEIN_KEY_PWA_25698 }}
              </s-label>
            </div>
            <div class="cur-order-status">
              <i
                class="status-icon"
                :class="'order-status-' + orderStatusColor(curOrder.orderStatus)"
              >
              </i>
              {{ orderStatusText(curOrder.orderStatus) }}
            </div>
            <div class="cur-order-goods">
              <div
                v-for="(i, picInx) in getOrderGoodsInfo(curOrder)"
                :key="picInx"
                class="order-goods-item"
              >
                <img
                  :src="transformImg({ img: i.goods_image })"
                  :alt="i.goods_name"
                />
                <div :class="['quality-number', i.quantity > 1 ? 'more-number' : '' ]">
                  x{{ i.quantity }}
                </div>
              </div>
            </div>
            <div class="cur-order-time">
              {{ language.SHEIN_KEY_PWA_25771 }} {{ handlerDate(curOrder.addTime) }}
            </div>
            <div class="cur-order-number">
              <span class="number">{{ strTemplate(curOrder.orderGoodsSum, language.SHEIN_KEY_PWA_25880) }}</span>
              <span class="price">{{ language.SHEIN_KEY_PWA_25878 }} {{ getOrderPrice(curOrder) }}</span>
            </div>
          </div>
          <!-- 展示提示文案 -->
          <div 
            class="notice-check-tips"
            v-html="language.SHEIN_KEY_PWA_25668"
          ></div>
          <!-- 前单商品信息展示区域 -->
          <div class="pre-order__warp">
            <div
              v-for="preOrder in codRiskPreOrderList"
              :key="preOrder.billno"
              class="pre-order__list"
            >
              <div class="pre-order__check">
                <s-radio
                  v-model="preOrder.selectedAll"
                  :label="true"
                  :toggle="true"
                  :disabled="preOrder.isDisabled"
                  gap=".32rem"
                  @change="changeItemEvt"
                />
              </div>
              <div class="pre-order__ctn">
                <div class="cur-order-title">
                  <span class="order-info">{{ language.SHEIN_KEY_PWA_16822 }}: {{ preOrder.billno }}</span>
                </div>
                <div class="cur-order-status">
                  <i
                    class="status-icon"
                    :class="'order-status-' + orderStatusColor(preOrder.order_status)"
                  >
                  </i>
                  {{ orderStatusText(preOrder.order_status) }}
                </div>
                <div class="cur-order-goods">
                  <div
                    v-for="(i, picInx) in getGoodsSumSort(preOrder.goods_list)"
                    :key="picInx"
                    class="order-goods-item"
                  >
                    <img
                      :src="transformImg({ img: i.goods_thumb })"
                      :alt="i.goods_thumb"
                    />
                    <div :class="['quality-number', i.goods_num > 1 ? 'more-number' : '' ]">
                      x{{ i.goods_num }}
                    </div>
                  </div>
                </div>
                <div class="cur-order-time">
                  {{ language.SHEIN_KEY_PWA_25771 }} {{ handlerDate(preOrder.add_time) }}
                </div>
                <div class="cur-order-number">
                  <span class="number">{{ strTemplate(preOrder.goods_total_num, language.SHEIN_KEY_PWA_25880) }}</span>
                  <span
                    v-if="preOrder.total_price"
                    class="price"
                  >{{ language.SHEIN_KEY_PWA_25878 }} {{ preOrder.total_price.amountWithSymbol }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template #footer>
          <div class="pre-cod-risk__footer">
            <div class="select-all__ctn">
              <s-radio
                v-model="selectedAll"
                :label="true"
                :toggle="true"
                gap=".32rem"
                :disabled="isDisabled"
                @change="changeAllEvt"
              >
                {{ language.SHEIN_KEY_PWA_25772 }}
              </s-radio>
            </div>
            <div class="pre-need-order">
              <s-button
                :type="['primary', 'H72PX']"
                :disabled="disableConfirmBtn"
                @click="confirmCodRiskOrderEvt"
              >
                {{ language.SHEIN_KEY_PWA_25637 }}
              </s-button>
            </div>
          </div>
        </template>
      </s-drawer>
    </SLazyMount>

    <!-- 二次确认弹框 -->
    <SLazyMount>
      <s-dialog
        v-model:visible="confirmCodeRiskOrder"
        :append-to-body="true"
        :close-on-click-modal="false"
        :show-close="true"
        @close-from-icon="closeMoreCodRiskOrderPop"
      >
        <template #title>
          {{ language.SHEIN_KEY_PWA_25641 }}
        </template>
        <div
          v-html="confirmCodRiskOrderText"
        >
        </div>
        <template #footer>
          <s-button-group :width="'100%'">
            <s-button-group-item
              :type="['primary', 'H72PX']"
              @click="cancelForSureEvt"
            >
              {{
                confirmPreOrderType == 1 ? language.SHEIN_KEY_PWA_25643 : language.SHEIN_KEY_PWA_25676
              }}
            </s-button-group-item>
            <s-button-group-item
              :type="['H72PX']"
              @click="takeAThoughtEvt"
            >
              {{ language.SHEIN_KEY_PWA_25642 }}
            </s-button-group-item>
          </s-button-group>
        </template>
      </s-dialog>
    </SLazyMount>
  </div>
</template>
<script>
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import { SRadio } from '@shein-aidc/sui-radio/mobile'
import { SLabel } from '@shein-aidc/sui-label/mobile'
import { SButtonGroupItem } from '@shein-aidc/sui-button-group-item/mobile'
import { SButtonGroup } from '@shein-aidc/sui-button-group/mobile'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { defineComponent } from 'vue'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import orderLogic from 'public/src/pages/common/orderLogic'
import { submitCodOrderAuditResultsFun } from 'public/src/pages/user/child_pages/orders/utils'
import { transformImg, template as strTemplate } from '@shein/common-function'
export default defineComponent({
  directives: { tap, expose },
  components: {
    SLazyMount,
    SButtonGroupItem,
    SButtonGroup,
    SDialog,
    SLabel,
    SRadio,
    SButton,
    SDrawer,
  },
  emits: ['closeCodRiskOrderPop'],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    curOrder: {
      type: Object,
      default() {
        return {}
      }
    },
    codRiskPreOrderList: {
      type: Array,
      default() {
        return []
      }
    },
    codRiskOrderText: {
      type: String,
      default: ''
    },
    pageFrom: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      drawerShow: false,
      selectedAll: false,
      showPreCodeRiskOrder: false,
      confirmCodeRiskOrder: false,
      confirmCodRiskOrderText: '',
      showPopover: false,
      isDisabled: false,
      needOrCancelType: '',
      confirmPreOrderType: '',
      showPreCodeRiskOrderHistory: '',
      isShowSecondconfirmation: false
    }
  },
  computed: {
    disableConfirmBtn () {
      return this.codRiskPreOrderList?.filter(item => item.selectedAll)?.length == 0
    }
  },
  watch: {
    show (val) {
      this.drawerShow = !!val
      !!val && this.initPageDataEvt()
    },
    showPreCodeRiskOrder (val) {
      if (val) {
        setTimeout(() => {
          this.showPopover = true
        }, 1000)
      }
    }
  },
  methods: {
    transformImg,
    strTemplate,
    initPageDataEvt () {
      this.codRiskPreOrderList?.forEach(item => {
        if(item.order_status == 10) {
          item.isDisabled = true
          item.selectedAll = true
        } else {
          item.isDisabled = false
          item.selectedAll = false
        }
      })
      const handle = this.codRiskPreOrderList?.every(i => i.order_status == 10)
      if(handle) {
        this.selectedAll = true
        this.isDisabled = true
      }
    },
    changeAllEvt (val) {
      this.codRiskPreOrderList?.forEach(item => {
        if(item.order_status != 10) {
          item.selectedAll = val
        }
      })
    },
    changeItemEvt () {
      let allLen = this.codRiskPreOrderList?.length
      let allSelectedLen = this.codRiskPreOrderList?.filter(item => item.selectedAll)?.length

      this.selectedAll = allLen == allSelectedLen
    },
    handlerDate(date) {
      return orderLogic.orderDateFormat(date)
    },
    orderStatusText(status) {
      let orderStatusInfo = orderLogic.orderStatus(this.language)
      return orderStatusInfo[status]
    },
    orderStatusColor(status) {
      let orderStatusColorInfo = orderLogic.orderStatusColor()
      return orderStatusColorInfo[status]
    },
    getGoodsSumSort (goodsList = []) {
      if (!goodsList?.length) return []
      let tempGoodsList = JSON.parse(JSON.stringify(goodsList))
      return tempGoodsList?.sort((pre, next) => pre.goods_num - next.goods_num)
    },
    getOrderPrice (order = {}) {
      let { currencyTotalPrice, totalPrice } = order || {}
      return currencyTotalPrice ? currencyTotalPrice.amountWithSymbol : (totalPrice ? totalPrice.amountWithSymbol : '')
    },
    getOrderGoodsNum (orderGoodsSum) {
      let { SHEIN_KEY_PWA_14952, SHEIN_KEY_PWA_15456 } = this.language || {}
      return `${+orderGoodsSum} ${+orderGoodsSum == 1 ? SHEIN_KEY_PWA_14952 : SHEIN_KEY_PWA_15456}`
    },
    getOrderGoodsInfo (order = {}) {
      let { orderGoodsList = [] } = order || {}
      let tempOrderGoodsList = orderGoodsList?.flat(Infinity) || []
      // 合并相同的商品
      let orderGoodsListMap = tempOrderGoodsList.reduce((acc, cur) => {
        let { goods_id, quantity } = cur || {}
        if (acc[goods_id]) {
          acc[goods_id].quantity += Number(quantity)
        } else {
          acc[goods_id] = {
            ...cur,
            goods_image: cur?.product?.goods_img || '',
            goods_name: cur?.product?.goods_name || '',
            quantity: Number(cur.quantity)
          }
        }
        return acc
      }, {})

      return Object.values(orderGoodsListMap)
    },
    closeCodRiskOrderPop () {
      this.sendCodRiskOrderEvt({ type: 'close', result: '-' })

      this.$emit('closeCodRiskOrderPop')
    },
    sendCodRiskOrderEvt ({ type, result } = {}) {
      daEventCenter.triggerNotice({
        daId: this.pageFrom == 'order_list' ? '1-20-1-72' : '1-19-1-111',
        extraData: {
          order_no: this.curOrder?.billno,
          type,
          result,
        }
      })
    },
    closeSecondconfirmation () {
      this.isShowSecondconfirmation = false
    },
    sendCodRiskOrderExposePreviousEvt ({ is_back } = {}) {
      daEventCenter.triggerNotice({
        daId: this.pageFrom == 'order_list' ? '1-20-1-73' : '1-19-1-112',
        extraData: {
          order_no: this.curOrder?.billno,
          num: this.codRiskPreOrderList?.length,
          is_back,
        }
      })
    },
    sendCodRiskOrderClickPreviousEvt ({ type } = {}) {
      daEventCenter.triggerNotice({
        daId: this.pageFrom == 'order_list' ? '1-20-1-74' : '1-19-1-113',
        extraData: {
          order_no: this.curOrder?.billno,
          is_back: this.showPreCodeRiskOrderHistory,
          type,
        }
      })
    },
    sendCodRiskOrderClickEvt ({ type } = {}) {
      daEventCenter.triggerNotice({
        daId: this.pageFrom == 'order_list' ? '1-20-1-76' : '1-19-1-115',
        extraData: {
          order_no: this.curOrder?.billno,
          type,
        }
      })
    },
    sendCodRiskOrderResultEvt ({ result } = {}) {
      daEventCenter.triggerNotice({
        daId: this.pageFrom == 'order_list' ? '1-20-1-77' : '1-19-1-116',
        extraData: {
          result,
        }
      })
    },
    sendCodRiskOrderExposeConfirmEvt () {
      daEventCenter.triggerNotice({
        daId: this.pageFrom == 'order_list' ? '1-20-1-75' : '1-19-1-114',
        extraData: {
          order_no: this.curOrder.billno
        }
      })
    },
    async needOrCancelTheOrderEvt (type) {
      this.needOrCancelType = type
      let res = await submitCodOrderAuditResultsFun({
        current_order: {
          billno: this.curOrder?.billno,
          risk_status: type
        }
      })
      if (res?.code == '0') {
        this.sendCodRiskOrderEvt(type == 1 ? { type: 'need', result: '1' } : { type: 'cancel', result: '1' })
        if (this.codRiskPreOrderList?.length && !this.codRiskPreOrderList?.every(i => i?.order_status == '10')) {
          this.$emit('closeCodRiskOrderPop')

          this.showPreCodeRiskOrder = true
          this.showPreCodeRiskOrderHistory = 0
          this.sendCodRiskOrderExposePreviousEvt({ is_back: 0 })
        } else {
          this.$emit('closeCodRiskOrderPop')
          SToast({ htmlString: type == 1 ? this.language?.SHEIN_KEY_PWA_25639 : this.language.SHEIN_KEY_PWA_25640 })
          
          location.reload()
        }
      } else {
        this.sendCodRiskOrderEvt(type == 1 ? { type: 'need', result: '0' } : { type: 'cancel', result: '0' })
        this.$emit('closeCodRiskOrderPop')
        if (res?.code == '300378') {
          SToast({ htmlString: res?.tips || this.language?.SHEIN_KEY_PWA_25888 })
        } else {
          SToast({ htmlString: this.language?.SHEIN_KEY_PWA_25888 })
        }
      }
    },
    closePreCodRiskOrderPop () {
      // 点击右上角icon关闭弹框
      this.showPreCodeRiskOrder = false

      this.confirmPreOrderType = 1
      this.confirmCodRiskOrderText = this.language?.SHEIN_KEY_PWA_25669 || ''

      this.confirmCodeRiskOrder = true
      this.sendCodRiskOrderClickPreviousEvt({ type: 'all_cancel' })
      this.sendCodRiskOrderExposeConfirmEvt()
    },
    closeMoreCodRiskOrderPop () {
      this.confirmCodeRiskOrder = false

      this.showPreCodeRiskOrder = true
      this.showPreCodeRiskOrderHistory = 1
      this.sendCodRiskOrderClickEvt({ type: 'close' })
      this.sendCodRiskOrderExposePreviousEvt({ is_back: 1 })
    },
    confirmCodRiskOrderEvt () {
      this.showPreCodeRiskOrder = false

      let selectedPreOrderList = this.codRiskPreOrderList?.filter(item => item.selectedAll)
      if (selectedPreOrderList?.length == this.codRiskPreOrderList?.length) {
        // 全部选择
        this.confirmPreOrderType = 3
        this.submitAllSelectedPreOrderEvt()
        this.sendCodRiskOrderClickPreviousEvt({ type: 'all_need' })
      } else {
        // 部分选择
        this.confirmPreOrderType = 2
        let existUnShippedOrderList = this.codRiskPreOrderList?.filter(item => !item.selectedAll)?.filter(item => [4, 6]?.includes(item?.order_status))
        let unSelectedOrderList = this.codRiskPreOrderList?.filter(item => !item.selectedAll)
        if (!existUnShippedOrderList?.length) {
          this.confirmCodRiskOrderText = this.language?.SHEIN_KEY_PWA_25674 || ''
        } else if (existUnShippedOrderList?.length == unSelectedOrderList?.length) {
          this.confirmCodRiskOrderText = this.language?.SHEIN_KEY_PWA_25673 || ''
        } else {
          this.confirmCodRiskOrderText = this.language?.SHEIN_KEY_PWA_25675 || ''
        }
        this.sendCodRiskOrderClickPreviousEvt({ type: 'other' })

        this.confirmCodeRiskOrder = true

        this.sendCodRiskOrderExposeConfirmEvt()
      }
    },
    takeAThoughtEvt () {
      this.confirmCodeRiskOrder = false

      this.showPreCodeRiskOrder = true
      this.showPreCodeRiskOrderHistory = 1
      this.sendCodRiskOrderClickEvt({ type: 'thought' })
      this.sendCodRiskOrderExposePreviousEvt({ is_back: 1 })
    },
    cancelForSureEvt () {
      this.sendCodRiskOrderClickEvt({ type: 'sure' })
      if (this.confirmPreOrderType == 1) {
        this.submitAllCancelPreOrderEvt()
      } else {
        this.submitPartCancelPreOrderEvt()
      }
    },
    async submitPartCancelPreOrderEvt () {
      let front_order_list = this.codRiskPreOrderList?.map(item => ({
        billno: item?.billno,
        is_need: item?.selectedAll ? 1 : 0
      }))
      let res = await submitCodOrderAuditResultsFun({ front_order_list, origin_billno: this.curOrder?.billno })
      if (res?.code == '0') {
        this.sendCodRiskOrderResultEvt({ result: 1 })
        let existUnShippedOrderList = this.codRiskPreOrderList?.filter(item => !item.selectedAll)?.filter(item => [4, 6]?.includes(item?.order_status))
        let unSelectedOrderList = this.codRiskPreOrderList?.filter(item => !item.selectedAll)
        let selectedUnShippedOrderList = this.codRiskPreOrderList?.filter(item => item.selectedAll)?.filter(item => [4, 6]?.includes(item?.order_status))
        if (!selectedUnShippedOrderList.length) {
          SToast({ htmlString: this.language?.SHEIN_KEY_PWA_25651 })
        } else if (selectedUnShippedOrderList.length) {
          SToast({ htmlString: this.language?.SHEIN_KEY_PWA_25647 })
        } else if (!existUnShippedOrderList?.length) {
          SToast({ htmlString: this.language?.SHEIN_KEY_PWA_25646 })
        } else if (existUnShippedOrderList?.length == unSelectedOrderList?.length) {
          SToast({ htmlString: this.language?.SHEIN_KEY_PWA_25647 })
        } else {
          SToast({ htmlString: this.language?.SHEIN_KEY_PWA_25648 })
        }
        location.reload()
      } else {
        this.sendCodRiskOrderResultEvt({ result: 0 })
        this.confirmCodeRiskOrder = false
        if (res?.code == '300378') {
          SToast({ htmlString: res?.tips || this.language?.SHEIN_KEY_PWA_25888 })
        } else {
          SToast({ htmlString: this.language.SHEIN_KEY_PWA_25888 })
        }
      }
    },
    async submitAllCancelPreOrderEvt () {
      let front_order_list = this.codRiskPreOrderList?.map(item => ({
        billno: item?.billno,
        is_need: 0
      }))
      let res = await submitCodOrderAuditResultsFun({ front_order_list, origin_billno: this.curOrder?.billno })
      if (res?.code == '0') {
        this.sendCodRiskOrderResultEvt({ result: 1 })
        let existUnShippedOrderList = this.codRiskPreOrderList?.filter(item => [4, 6]?.includes(item?.order_status))
        if (!existUnShippedOrderList?.length) {
          SToast({ htmlString: this.language?.SHEIN_KEY_PWA_25649 })
        } else if (existUnShippedOrderList?.length == this.codRiskPreOrderList?.length) {
          SToast({ htmlString: this.language?.SHEIN_KEY_PWA_25650 })
        } else {
          SToast({ htmlString: this.language?.SHEIN_KEY_PWA_25651 })
        }
        location.reload()
      } else {
        this.sendCodRiskOrderResultEvt({ result: 0 })
        this.confirmCodeRiskOrder = false
        if (res?.code == '300378') {
          SToast({ htmlString: res?.tips || this.language?.SHEIN_KEY_PWA_25888 })
        } else {
          SToast({ htmlString: this.language.SHEIN_KEY_PWA_25888 })
        }
      }
    },
    async submitAllSelectedPreOrderEvt () {
      let front_order_list = this.codRiskPreOrderList?.map(item => ({
        billno: item?.billno,
        is_need: 1
      }))
      let res = await submitCodOrderAuditResultsFun({ front_order_list, origin_billno: this.curOrder?.billno })
      if (res?.code == '0') {
        this.sendCodRiskOrderResultEvt({ result: 1 })
        let existUnShippedOrderList = this.codRiskPreOrderList?.filter(item => [4, 6]?.includes(item?.order_status))
        if (existUnShippedOrderList?.length) {
          SToast({ htmlString: this.language?.SHEIN_KEY_PWA_25644 })
        } else {
          SToast({ htmlString: this.language?.SHEIN_KEY_PWA_25645 })
        }
        location.reload()
      } else {
        this.sendCodRiskOrderResultEvt({ result: 0 })
        this.showPreCodeRiskOrder = false
        if (res?.code == '300378') {
          SToast({ htmlString: res?.tips || this.language?.SHEIN_KEY_PWA_25888 })
        } else {
          SToast({ htmlString: this.language.SHEIN_KEY_PWA_25888 })
        }
      }
    }
  },
})
</script>
<style lang="less">
.pre-cod-risk__pop{
  .sui-drawer__container{
    overflow: unset;
  }
  .sui-popover__content-body{
    padding: .24rem .32rem;
  }
}
.cod-risk-order__text{
  margin-top: 20/75rem;
}
.pre-cod-risk__warp{
  .drawer-popover{
    position: absolute;
    .right(0);
    bottom: calc(100% + .21333rem);
    max-width: 98%;
    margin: 0 1%;
    .drawer-popover-ctn{
      padding: 16/75rem 20/75rem;
      .font-dpr(24px);
      background: #fff;
      position: relative;
      &::before{
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        bottom: -10/75rem;
        .right(6/75rem);
        border-left: 12/75rem solid transparent;
        border-right: 12/75rem solid transparent;
        border-top: 12/75rem solid #fff;
      }
    }
    i{
      position: absolute;
      top: 0;
      .right(0);
      .font-dpr(28px);
      cursor: pointer;
    }
  }
  .cur-order__warp{
    padding: 20/75rem 24/75rem;
  }
  .cur-order-title{
    display: flex;
    align-items: center;
  }
  .order-info{
    color: #000;
    .font-dpr(24px);
    .margin-r(8/75rem);
  }
  .cur-order-time{
    .font-dpr(24px);
    color: #666;
  }
  .cur-order-status{
    padding: 4px 0 8px 0;
    font-size: 12px;
    color: #222;
    display: flex;
    align-items: center;
  }
  .status-icon{
    width: 12/75rem;
    height: 12/75rem;
    border-radius: 50%;
    display: inline-block;
    background: #ccc;
    .margin-r(8/75rem);
    &.order-status-green {
      background: @sui_color_safety;
    }
    &.order-status-red {
      background: @sui_color_unusual;
    }
    &.order-status-blue {
      background: @sui_color_link;
    }
    &.order-status-grey {
      background: @sui_color_gray_light2;
    }
  }
  .cur-order-number{
    display: flex;
    align-items: center;
    color: #666;
    .font-dpr(24px);
    .number{
      .margin-r(12/75rem);
    }
  }
  .cur-order-goods{
    padding-bottom: 20/75rem;
    display: flex;
    align-items: center;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .order-goods-item{
    width: 148/75rem;
    height: 148/75rem;
    .margin-r(8/75rem);
    position: relative;
    &:last-child{
      .margin-r(0);
    }
    img{
      object-fit: cover;
      width: 148/75rem;
      height: 148/75rem;
      display: block;
      outline: none;
      border: none;
    }
  }
  .quality-number{
    position: absolute;
    bottom: 4/75rem;
    .left(50%);
    height: 56/75rem;
    width: 56/75rem;
    .margin-l(-28/75rem);
    .font-dpr(24px);
    text-align: center;
    line-height: 56/75rem;
    background: rgba(255, 255, 255, 0.80);
    border-radius: 50%;
    color: #000;
    &.more-number{
      color: #fa6338;
    }
  }
  .notice-check-tips{
    padding: 24/75rem;
    background: #F6F6F6;
    .font-dpr(26px);
    font-weight: 400;
    color: #000;
    line-height: normal;
  }
  .pre-order__list{
    padding: 20/75rem 24/75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .pre-order__ctn{
      flex: 1;
    }
  }
}
.pre-cod-risk__pop{
  .sui-drawer__normal-footer{
    margin-top: 0;
    height: auto !important; /* stylelint-disable-line declaration-no-important */
    line-height: initial !important; /* stylelint-disable-line declaration-no-important */
  }
  .pre-cod-risk__footer{
    padding: 16/75rem 20/75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .select-all__ctn{
      display: flex;
      align-items: center;
    }
  }
}
</style>
