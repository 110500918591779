<template>
  <s-drawer
    v-model:visible="drawerShow"
    :show-close="true"
    max-size="50%"
    append-to-body
    class="csp-notice-drawer"
    @close="closeModel"
  >
    <template #title>
      {{ language.SHEIN_KEY_PWA_24686 }}
    </template>
    <!-- tab -->
    <s-tab
      v-if="hasTab"
      v-model="activeIndex"
      class="tab-container"
      :type="'auto'"
      @change="onTabChange"
    >
      <s-tab-item
        v-for="(tab, index) in filterdPackageGoodsList"
        :id="index"
        :key="'tab-' + index"
        v-tap="{ id: daClickId, data: { billno, type: 'tab'} }"
      >
        <span>
          {{ language.SHEIN_KEY_PWA_15757 }}{{ index + 1 }}
        </span>
      </s-tab-item>
    </s-tab>
    <!-- 公告内容 -->
    <div
      :ref="'noticeContainer' + activeIndex"
      class="notice-container"
    >
      <div
        v-for="(notice, index) in noticeList"
        :key="index"
        class="notice-item"
        :class="{ 'hasExpired': notice.notice_status != 1 }"
      >
        <div class="icon-container">
          <i class="suiiconfont sui_icon_news_32px notice-icon"></i>
        </div>
        <div class="content">
          <p class="data">
            {{ timeTransformer({time: notice.submit_time * 1000, defaultCode: 'E-1'}) }}
          </p>
          <p class="content-text">
            {{ notice.notice_content }}
          </p>
        </div>
      </div>
    </div>
    <!-- 商品图片 -->
    <div class="product-container">
      <div
        v-for="(item, index) in packageProducts.slice(0, 5)"
        :key="item.id"
        class="product-pic"
      >
        <div
          v-if="packageProducts.length > 5 && index === 4"
          class="overlay-content"
        >
          <span>+{{ packageProducts.length - 5 }}</span>
        </div>
        <!-- <img
          :src="transformImg({ img: item.product.goods_img })"
          draggable="false"
          width="64"
          height="64"
        /> -->
        <div style="width:64px; height:64px">
          <CropImageContainer
            :img-src="transformImg({ img: item.product.goods_img })"
            fixed-ratio="1-1"
          />
        </div>
        <div class="item-quantity">
          <span :class="{'multi': item.quantity > 1}">x{{ item.quantity }}</span>
        </div>
      </div>
    </div>
  </s-drawer>
</template>

<script>
import { STabItem } from '@shein-aidc/sui-tab-item/mobile'
import { STab } from '@shein-aidc/sui-tab/mobile'
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { tap } from 'public/src/pages/common/analysis/directive'
import { defineComponent, nextTick } from 'vue'
import { timeTransformer, transformImg } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { CropImageContainer } from 'public/src/pages/components/CropImageContainer/index.js'

export default defineComponent({
  components: {
    CropImageContainer,
    STabItem,
    STab,
    SDrawer,
  },
  directives: {
    tap,
  },
  emits: ['close'],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    billno: {
      type: String,
      default: ''
    },
    pageFrom: {
      type: String,
      default: ''
    },
    daClickId: {
      type: String,
      default: ''
    },
    daExposeId: {
      type: String,
      default: ''
    },
    language: {
      type: Object,
      default: () => {}
    },
    cspNoticeList: {
      type: Object,
      default: () => {}
    },
    packageGoodsList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      activeIndex: 0,
      drawerShow: false,
      scrollPositions: {},
    }
  },
  computed: {
    onlyNoPackageNotice() {
      // 判断是否仅有无包裹号的异常公告
      return (
        this.cspNoticeList?.notice_content_list?.length > 0 &&
        this.cspNoticeList?.notice_content_list?.every(item => !item.package_no)
      )
    },
    filterdPackageGoodsList() {
      const noticePackageNo = this.cspNoticeList?.notice_content_list?.map(item => item.package_no)
      // 排除掉包裹号为空的
      return this.packageGoodsList?.filter(subArr => !subArr.some(obj => obj.reference_number === ''))
        ?.filter(subArr => subArr.some(obj => noticePackageNo?.includes(obj.reference_number)))
        || []
    },
    noticeList() {
      const noticeContentList = this.cspNoticeList?.notice_content_list || []
      const packageNo = this.filterdPackageGoodsList[this.activeIndex]?.[0]?.reference_number
      // 对应包裹号的异常公告
      const packageNoticeList = noticeContentList.filter(item => item.package_no === packageNo)
      // 无包裹号的异常公告
      const noPackageNoticeList = noticeContentList.filter(item => !item.package_no)

      return [...packageNoticeList, ...noPackageNoticeList].sort((a, b) => b.submit_time - a.submit_time)
    },
    packageProducts() {
      if (this.onlyNoPackageNotice) {
        for (let i = 0; i < this.packageGoodsList.length; i++) {
          return this.getAggregatedItems(this.packageGoodsList[i])
        }
      }
      return this.getAggregatedItems(this.filterdPackageGoodsList[this.activeIndex]) || []
    },
    hasTab() {
      return !this.onlyNoPackageNotice && this.filterdPackageGoodsList.length > 1
    }
  },
  watch: {
    show: {
      handler (val) {
        this.drawerShow = !!val
        this.scrollPositions = {}
        if(val) {
          this.onTabChange()
          daEventCenter.triggerNotice({
            daId: `${this.daExposeId}`,
            extraData: {
              order_no: this.billno,
            }
          })
        }
      },
      immediate: true
    }
  },
  updated() {
    if (this.hasTab) {
      this.$refs['noticeContainer' + this.activeIndex].addEventListener('scroll', this.archorScroll)
    }
  },
  methods: {
    timeTransformer,
    transformImg,
    closeModel() {
      this.$emit('close')
      daEventCenter.triggerNotice({
        daId: `${this.daClickId}`,
        extraData: {
          order_no: this.billno,
          type: 'close'
        }
      })
    },
    onTabChange() {
      nextTick(() => {
        let noticeContainer = this.$refs['noticeContainer' + this.activeIndex]
        if (noticeContainer) {
          noticeContainer.scrollTop = this.scrollPositions[this.activeIndex] || 0
        }
      })
    },
    archorScroll() {
      let noticeContainer = this.$refs['noticeContainer' + this.activeIndex]
      if (noticeContainer) {
        this.scrollPositions[this.activeIndex] = noticeContainer.scrollTop
      }
    },
    getAggregatedItems(currentPackage = []) {
      let goodsList = currentPackage

      const quantityMap = {}

      // 聚合goods_id相同的商品
      goodsList.forEach((current, index) => {
        if (!quantityMap[current.goods_id]) {
          // 服务端已经排序过了，这里对象转数组后顺序会乱，提前标记一个indexMark
          quantityMap[current.goods_id] = { ...current, indexMark: index }
        } else {
          quantityMap[current.goods_id].quantity = Number(current.quantity) + Number(quantityMap[current.goods_id].quantity)
        }
      })

      const aggregatedItems = Object.values(quantityMap)
      const sortedItems = aggregatedItems.sort((a, b) => a.indexMark - b.indexMark)

      return sortedItems
    }
  },
})
</script>
<style lang="less">
.csp-notice-drawer {
  .sui-drawer__body {
    .flexbox();
    flex-direction: column;
  }
}
</style>
<style lang="less" scoped>
.tab-container {
  flex-shrink: 0;
}
.notice-container {
  overflow-y: auto;
  color: #222;
  .icon-container {
    min-width: 60/75rem;
    height: 60/75rem;
    background: #f6f6f6;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16/75rem;
  }
  .notice-item {
    display: flex;
    padding: 24/75rem;
    border-bottom: 0.5px solid #e5e5e5;
  }
  .content {
    text-align: left;
  }
  .data {
    font-size: 24/75rem;
    font-weight: bold;
    font-family: 'SF UI Text';
  }
  .content-text {
    font-size: 28/75rem;
    font-family: 'SF UI Text';
    word-break: break-word;
  }
  .hasExpired {
    color: @sui_color_gray_light2;
  }
}
.notice-container::-webkit-scrollbar {
  width: 8/75rem;
}
.notice-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4/75rem;
}
.product-container {
  display: flex;
  overflow-x: hidden;
  margin: 20/75rem 24/75rem 68/75rem;
  white-space: nowrap;
  flex-shrink: 0;
  .product-pic {
    display: inline-block;
    position: relative;
    margin-right: 16/75rem;
  }
  .item-quantity {
    position: absolute;
    background: rgba(255, 255, 255, 0.9);
    height: 56/75rem;
    width: 56/75rem;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    & > span {
      font-size: 24/75rem;
      font-weight: bold;
      color: @sui_color_gray_dark1;
      &.multi {
        color: @sui_color_discount;
      }
    }
  }
  .overlay-content {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 48/75rem;
    font-weight: bold;
  }
}
</style>
